<template>
  <div class="AllCalendarEventsEmpty__wrapper">
    <div class="AllCalendarEventsEmpty__illustration">
      <DashboardIllustrationEmptyMeetingsList />
    </div>
    <h3 class="AllCalendarEventsEmpty__title">There are no meetings today</h3>
    <p v-if="variant === 'NORMAL'" class="AllCalendarEventsEmpty__text">
      You don't have any scheduled meetings, but you can schedule your next
      meeting using the available rooms
    </p>
    <button class="AllCalendarEventsEmpty__button" @click="handleScheduleClick">
      Schedule meeting
    </button>
  </div>
</template>
<script setup lang="ts">
type Props = {
  variant: "NORMAL" | "SHORT";
};
defineProps<Props>();

const emit = defineEmits<{
  (e: "schedule-click"): void;
}>();

const handleScheduleClick = () => {
  emit("schedule-click");
};
</script>
<style scoped lang="scss">
.AllCalendarEventsEmpty {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &__illustration {
    margin-bottom: 16px;
  }
  &__title {
    @include header-xs-bold;
    color: $color-element-dark-strong;
    margin-bottom: 6px;
  }
  &__text {
    @include body-s;
    color: $color-element-dark-medium;
    margin-bottom: 12px;
    @include md {
      @include body-l;
    }
  }
  &__button {
    @include label-xl;
    background: none;
    border: none;
    color: $color-primary;
    padding: 11px 18px;
    cursor: pointer;
  }
}
</style>
